import React from "react";
import styled from "styled-components";
import { useShoppingCart } from "use-shopping-cart";

import logo from "../assets/logo.png";

import { Link } from "react-router-dom";

const Container = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr auto;
    > div {
      justify-self: center;
    }
    :nth-child(1) {
      order: 1;
      justify-self: start;
      a {
        display: block;
      }
    }
    :nth-child(3) {
      order: 2;
    }
    :nth-child(2) {
      order: 3;
      grid-column: 1 / span 2;
    }
  }

  padding: 1rem;

  :nth-child(1) {
    padding-top: 12px;

    a {
      padding: 5px 10px;
      text-transform: uppercase;
      font-size: 13px;
    }
  }

  :nth-child(2) {
    justify-self: center;
  }
  :nth-child(3) {
    justify-self: end;

    a {
      display: inline-block;
      border-radius: 50px;
      line-height: 45px;
      padding: 0 35px;
      border: none;
      background-color: rgba(0, 0, 0, 0.8);
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      font-size: 13px;
    }
  }
  @keyframes rollout {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(5deg);
    }
    50% {
      transform: rotate(0eg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  .shake {
    animation: 0.8s rollout;
    animation-delay: 0.5s;
  }
`;

const Header = () => {
  const { cartDetails } = useShoppingCart();
  const cartCount = cartDetails.sku_kalenteri_2025?.quantity ?? 0;
  // const [lastCartCount, setLastCartCount] = React.useState(cartCount);

  // React.useEffect(() => {
  //   setLastCartCount(cartCount);
  // }, [lastCartCount, cartCount]);

  return (
    <Container>
      <div>
        <Link to="/">Kauppa</Link>
        <Link to="/yhteystiedot">Yhteystiedot</Link>
      </div>
      <div>
        <img src={logo} alt="Liisart" />
      </div>
      <div>
        <Link
          to="/ostoskori"
          key={cartCount}
          className={cartCount > 0 ? "shake" : ""}
        >
          Ostoskori ({cartCount})
        </Link>
      </div>
    </Container>
  );
};

export default Header;
